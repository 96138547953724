<template>
    <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.CalcCircInfos.Title_1}} &nbsp; <span style="font-size:1vw">{{this.$lang.CalcCircInfos.Title_2}}</span>

            <p v-if="this.$store.state.isCompleted" class="control" slot="right" style="margin-right: 16px">
                <saveButton />
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="is-warning" @click="openCircuitDevices" style="height: 88%;">
                    {{this.$lang.Calculation.InnerTitle_14}} <br /> {{this.$lang.Calculation.InnerTitle_15}} <br />{{this.$lang.Calculation.InnerTitle_16}}
                </b-button>
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 0.75rem;">
            <div class="columns is-multiline is-mobile">
                <div class="column is-2" style="background-color: #FFFFFF; width: 10%; display: flex; min-height: 100vh; flex-direction: column;">
                    <verticalStep-bar />
                </div>
                <div class="column is-10">
                    <calculationTabs />
                    <div style="padding: 0.5rem;">
                        <outputCircBtns />
                    </div>
                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="column">
                            <span style="font-size:1vw"> {{ this.$lang.CalcCircInfos.Title_4 }} </span>
                            <b-field :label="this.$lang.CalcCircInfos.Title_5"></b-field>
                            <b-field grouped>
                                <b-field :label="this.$lang.CalcCircInfos.TableDefinition" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.TableDefinition">
                                        <b-input v-model="definition_2"></b-input>
                                    </b-tooltip>
                                </b-field>

                                <b-field :label="this.$lang.CalcCircInfos.Type" :label-position="labelPosition" :type="{ 'is-danger': hasError_type }" :message="emptyWarning_type">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Type">
                                        <b-select v-if="typesList" v-model="type" @input="getCircuitDevice_Brands">
                                            <option v-for="option in typesList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>

                                <b-field :label="this.$lang.CalcCircInfos.Brand" :label-position="labelPosition" :type="{ 'is-danger': hasError_brand }" :message="emptyWarning_brand">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Brand">
                                        <b-select v-if="brandsList" v-model="brand" @input="getCircuitDevice_Codes">
                                            <option v-for="option in brandsList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>

                                <b-field :label="this.$lang.CalcCircInfos.Code" :label-position="labelPosition" :type="{ 'is-danger': hasError_code }" :message="emptyWarning_code">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Code">
                                        <b-select v-if="codesList" v-model="code" @input="getCircuitDevice_Ins">
                                            <option v-for="option in codesList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" :type="{ 'is-danger': hasError_in_A }" :message="emptyWarning_in_A">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.In_A"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.In_A"></span>
                                        </template>
                                        <b-select v-if="in_valList" v-model="in_A" @input="getCircuitDevice_Pole">
                                            <option v-for="option in in_valList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>

                                <b-field v-if="isPoleNumVisible" :label="this.$lang.CalcCircInfos.PoleNumber" :label-position="labelPosition" :type="{ 'is-danger': hasError_poleNumber }" :message="emptyWarning_poleNumber">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PoleNumber">
                                        <b-select v-if="poleList" v-model="poleNumber" @input="getCircuitDevice_Sbt_Cekmeceli">
                                            <option v-for="option in poleList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="is_sbt_cekmeceliVisible" :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :label-position="labelPosition" :type="{ 'is-danger': hasError_sbt_cekmeceli }" :message="emptyWarning_sbt_cekmeceli">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Sbt_cekmeceli">
                                        <b-select v-if="sbt_cekmeceliList" v-model="sbt_cekmeceli" @input="getCircuitDevice_Power">
                                            <option v-for="option in sbt_cekmeceliList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isPowerVisible" :label="this.$lang.CalcCircInfos.Power" :label-position="labelPosition" :type="{ 'is-danger': hasError_power }" :message="emptyWarning_power">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Power">
                                        <b-select v-if="powerList" v-model="power" @input="getCircuitDevice_Pv">
                                            <option v-for="option in powerList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>

                            <b-field :label="this.$lang.CalcCircInfos.Title_6"></b-field>
                            <b-field grouped>
                                <b-field :label="this.$lang.CalcCircInfos.TableDefinition" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.TableDefinition">
                                        <b-input v-model="definition_3"></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConnType" :label-position="labelPosition" :type="{ 'is-danger': hasError_connType }" :message="emptyWarning_connType">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConnType">
                                        <b-select v-if="connTypeList" v-model="connType" @input="getCablePositions">
                                            <option v-for="option in connTypeList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isCablePosVisible" :label="this.$lang.CalcCircInfos.CablePos" :label-position="labelPosition" :type="{ 'is-danger': hasError_cablePos }" :message="emptyWarning_cablePos">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.CablePos">
                                        <b-select v-if="cablePosList" v-model="cablePos" @input="getConductorDimensions">
                                            <option v-for="option in cablePosList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.PhaseNumber" :label-position="labelPosition" :type="{ 'is-danger': hasError_phaseNumber_3 }" :message="emptyWarning_phaseNumber_3">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PhaseNumber">
                                        <b-select v-if="phaseNumber_3_List" v-model="phaseNumber_3">
                                            <option v-for="option in phaseNumber_3_List"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorDimension" :label-position="labelPosition" :type="{ 'is-danger': hasError_conductorDimension }" :message="emptyWarning_conductorDimension">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorDimension">
                                        <b-select v-if="condDimensionList" v-model="conductorDimension" @input="getConductor_Imax_and_Power">
                                            <option v-for="option in condDimensionList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorLength" :label-position="labelPosition" style="width: 7%;" :type="{ 'is-danger': hasError_conductorLength_3 }" :message="emptyWarning_conductorLength_3">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorLength">
                                        <b-numberinput v-model="conductorLength_3" type="number" :controls=false step="any" @input="setPositiveNumber()"></b-numberinput>
                                    </b-tooltip>
                                </b-field>

                                <b-field :label="this.$lang.CalcCircInfos.ConductorTemp" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorTemp">
                                        <b-input v-model="conductorTemp" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 10%;">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <b-input v-model="Tmax_inPanel" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 10%;">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Imax"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <b-input v-model="Imax_3" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.LostPower" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.LostPower">
                                        <b-input v-model="lostPower_3" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                            </b-field>

                            <p class="control">
                                <b-button type="is-success" :label="this.$lang.CalcCircInfos.CalcAddButton" @click="addToInputCircuitAndConductor" />
                            </p>

                            <div v-if="circuitcalculationdevicesdata.length != 0">
                                <b-field :label="this.$lang.CalcCircInfos.Title_7" style="padding-top: 2rem;"></b-field>
                                <b-table :data="circuitcalculationdevicesdata">

                                    <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                        {{ props.row.Definition }}
                                    </b-table-column>
                                    <b-table-column field="Type" :label="this.$lang.CalcCircInfos.Type" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.Type }}
                                    </b-table-column>
                                    <b-table-column field="Brand" :label="this.$lang.CalcCircInfos.Brand" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.Brand }}
                                    </b-table-column>
                                    <b-table-column field="Code" :label="this.$lang.CalcCircInfos.Code" :td-attrs="columnTdAttrs" v-slot="props" width="200">
                                        {{ props.row.Code }}
                                    </b-table-column>
                                    <b-table-column field="In_Value" :td-attrs="columnTdAttrs" width="100">
                                        <template v-slot:header="">
                                            <span v-html="$lang.CalcCircInfos.In_A"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ props.row.In_Value }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="Pole" :label="this.$lang.CalcCircInfos.PoleNumber" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                        {{ props.row.Pole }}
                                    </b-table-column>
                                    <b-table-column field="Sbt_Cekmeceli" :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.Sbt_Cekmeceli }}
                                    </b-table-column>
                                    <b-table-column field="Power" :label="this.$lang.CalcCircInfos.Power" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                        {{ props.row.Power }}
                                    </b-table-column>
                                    <b-table-column field="CalcResult" :td-attrs="columnTdAttrs" width="150">
                                        <template v-slot:header="">
                                            <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ truncate(props.row.Pv, 1) }}
                                        </template>
                                    </b-table-column>

                                    <b-table-column field="Action" v-slot="props">
                                        <button class="button is-small is-light" @click="openUpdateInputCircuitCalcDeviceModal(props.row)">
                                            <b-icon icon="pen" size="is-small"></b-icon>
                                        </button>
                                        <button class="button is-small is-danger" @click="deleteFromInputCircuitCalcDevices(props.row)">
                                            <b-icon icon="delete" size="is-small"></b-icon>
                                        </button>
                                        <button class="button is-small is-light" @click="copyFromInputCircuitCalcDevices(props.row)" style="width: 30px">
                                            <!--<b-icon icon="file" size="is-small"></b-icon>-->
                                            <div class="material-symbols-outlined">
                                                content_copy
                                            </div>
                                        </button>
                                    </b-table-column>
                                </b-table>
                            </div>
                            <div v-if="conductordevicesdata.length != 0">
                                <b-table :data="conductordevicesdata">
                                    <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                        {{ props.row.Definition }}
                                    </b-table-column>
                                    <b-table-column field="ConnectionType" :label="this.$lang.CalcCircInfos.ConnType" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.ConnectionType }}
                                    </b-table-column>
                                    <b-table-column v-if="isCablePosVisible" :label="this.$lang.CalcCircInfos.CablePos" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.CablePosition }}
                                    </b-table-column>
                                    <b-table-column field="PhaseNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs" v-slot="props" width="200">
                                        {{ props.row.PhaseNumber }}
                                    </b-table-column>
                                    <b-table-column field="DimensionInfo" :label="this.$lang.CalcCircInfos.ConductorDimension" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                        {{ props.row.DimensionInfo }}
                                    </b-table-column>
                                    <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                        {{ props.row.ConductorLength }}
                                    </b-table-column>
                                    <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                        {{ props.row.ConductorTemperature }}
                                    </b-table-column>
                                    <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs" width="150">
                                        <template v-slot:header="">
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ props.row.MaxTemperature }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="I_Max" :td-attrs="columnTdAttrs" width="100">
                                        <template v-slot:header="">
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ truncate(props.row.I_Max, 1) }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                        {{ truncate(props.row.LostPower, 1) }}
                                    </b-table-column>
                                    <b-table-column field="CalcResult" :td-attrs="columnTdAttrs" width="100">
                                        <template v-slot:header="">
                                            <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ truncate(props.row.CalcResult, 1) }}
                                        </template>
                                    </b-table-column>

                                    <b-table-column field="Action" v-slot="props">
                                        <button class="button is-small is-light" @click="openInputCircuitDeviceConductorModal(props.row)">
                                            <b-icon icon="pen" size="is-small"></b-icon>
                                        </button>
                                        <button class="button is-small is-danger" @click="deleteFromInputCircuitDeviceConductors(props.row)">
                                            <b-icon icon="delete" size="is-small"></b-icon>
                                        </button>
                                        <button class="button is-small is-light" @click="copyFromInputCircuitDeviceConductors(props.row)" style="width: 30px">
                                            <!--<b-icon icon="file" size="is-small"></b-icon>-->
                                            <div class="material-symbols-outlined">
                                                content_copy
                                            </div>
                                        </button>
                                    </b-table-column>
                                </b-table>
                                <div style="padding: 0.5rem;">
                                    <div class="columns is-mobile is-multiline">
                                        <div class="field is-grouped" style="padding-top: 0.5rem">
                                            <b-field>
                                                <b-field :label="this.$lang.CalcCircInfos.Total_P_InpCirc"></b-field>
                                            </b-field>
                                            <b-field>
                                                {{ truncate(total_P_InputCircuit, 1) }}
                                            </b-field>
                                        </div>
                                    </div>
                                </div>
                                <div style="padding-top: 2rem"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section>
            <b-modal v-model="isInputCircuitDeviceUpdateModalActive" :width="960">
                <div class="card">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{{ this.$lang.CalcCircInfos.UpdateDevice }}</p>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.TableDefinition" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.TableDefinition">
                                            <b-input v-model="definition_2"></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.Type" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.Type">
                                            <b-select v-if="typesList" v-model="type" @input="getCircuitDevice_Brands">
                                                <option v-for="option in typesList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.Brand" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.Brand">
                                            <b-select v-if="brandsList" v-model="brand" @input="getCircuitDevice_Codes" style="width: 155px">
                                                <option v-for="option in brandsList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.Code" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.Code">
                                            <b-select v-if="codesList" v-model="code" @input="getCircuitDevice_Ins">
                                                <option v-for="option in codesList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label-position="labelPosition" style="width: 155px">
                                        <template #label>
                                            <span v-html="$lang.CalcCircInfos.In_A"></span>
                                        </template>
                                        <b-tooltip>
                                            <template v-slot:content>
                                                <span v-html="$lang.CalcCircInfos.In_A"></span>
                                            </template>
                                            <b-select v-if="in_valList" v-model="in_A" @input="getCircuitDevice_Pole">
                                                <option v-for="option in in_valList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.PoleNumber" :label-position="labelPosition" :type="{ 'is-danger': hasError_poleNumber }" :message="emptyWarning_poleNumber" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.PoleNumber" v-if="isPoleNumVisible">
                                            <b-select v-if="poleList" v-model="poleNumber" @input="getCircuitDevice_Sbt_Cekmeceli">
                                                <option v-for="option in poleList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                        <b-tooltip :label="this.$lang.CalcCircInfos.PoleNumber" v-if="!isPoleNumVisible">
                                            <b-select v-if="poleList" v-model="poleNumber" @input="getCircuitDevice_Sbt_Cekmeceli" disabled>
                                                <option v-for="option in poleList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :label-position="labelPosition" :type="{ 'is-danger': hasError_sbt_cekmeceli }" :message="emptyWarning_sbt_cekmeceli" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" v-if="is_sbt_cekmeceliVisible">
                                            <b-select v-if="sbt_cekmeceliList" v-model="sbt_cekmeceli" @input="getCircuitDevice_Power">
                                                <option v-for="option in sbt_cekmeceliList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                        <b-tooltip :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" v-if="!is_sbt_cekmeceliVisible">
                                            <b-select v-if="sbt_cekmeceliList" v-model="sbt_cekmeceli" @input="getCircuitDevice_Power" disabled>
                                                <option v-for="option in sbt_cekmeceliList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.Power" :label-position="labelPosition" :type="{ 'is-danger': hasError_power }" :message="emptyWarning_power" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.Power" v-if="isPowerVisible">
                                            <b-select v-if="powerList" v-model="power" @input="getCircuitDevice_Pv">
                                                <option v-for="option in powerList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                        <b-tooltip :label="this.$lang.CalcCircInfos.Power" v-if="!isPowerVisible">
                                            <b-select v-if="powerList" v-model="power" @input="getCircuitDevice_Pv" disabled>
                                                <option v-for="option in powerList"
                                                        :value="option"
                                                        :key="option">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                            </div>
                        </div>
                        <div class="content">
                            <footer class="modal-card-foot">
                                <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                          @click="isInputCircuitDeviceUpdateModalActive = false" />
                                <b-button :label="this.$lang.Menus.ModalUpdtBtn"
                                          type="is-primary"
                                          @click="updateInputCircuitCalcDevices()" />
                            </footer>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal v-model="isConductorDeviceUpdateModalActive" :width="960">
                <div class="card">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{{ this.$lang.CalcCircInfos.UpdateDeviceConductor }}</p>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.TableDefinition" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.TableDefinition">
                                            <b-input v-model="definition_3"></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.ConnType" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.ConnType">
                                            <b-select v-if="connTypeList" v-model="connType" @input="getCablePositions">
                                                <option v-for="option in connTypeList"
                                                        :value="option"
                                                        :key="option.id">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field v-if="isCablePosVisible" :label="this.$lang.CalcCircInfos.CablePos" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.CablePos">
                                            <b-select v-if="cablePosList" v-model="cablePos" @input="getConductorDimensions">
                                                <option v-for="option in cablePosList"
                                                        :value="option"
                                                        :key="option.id">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.PhaseNumber" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.PhaseNumber">
                                            <b-select v-if="phaseNumber_3_List" v-model="phaseNumber_3">
                                                <option v-for="option in phaseNumber_3_List"
                                                        :value="option"
                                                        :key="option.id">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.ConductorDimension" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.ConductorDimension">
                                            <b-select v-if="condDimensionList" v-model="conductorDimension" @input="getConductor_Imax_and_Power">
                                                <option v-for="option in condDimensionList"
                                                        :value="option"
                                                        :key="option.id">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.ConductorLength" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.ConductorLength">
                                            <b-numberinput v-model="conductorLength_3" type="number" :controls=false step="any" @input="setPositiveNumber()"></b-numberinput>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.ConductorTemp" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.ConductorTemp">
                                            <b-input v-model="conductorTemp" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label-position="labelPosition" style="width: 155px">
                                        <template #label>
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <b-tooltip>
                                            <template v-slot:content>
                                                <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                            </template>
                                            <b-input v-model="Tmax_inPanel" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.Imax" :label-position="labelPosition" style="width: 155px">
                                        <template #label>
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <b-tooltip>
                                            <template v-slot:content>
                                                <span v-html="$lang.CalcCircInfos.Imax"></span>
                                            </template>
                                            <b-input v-model="Imax_3" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.LostPower" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.LostPower">
                                            <b-input v-model="lostPower_3" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>

                            </div>
                        </div>
                        <div class="content">
                            <footer class="modal-card-foot">
                                <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                          @click="isConductorDeviceUpdateModalActive = false" />
                                <b-button :label="this.$lang.Menus.ModalUpdtBtn"
                                          type="is-primary"
                                          @click="updateInputCircuitDeviceConductors()" />
                            </footer>
                        </div>
                    </div>
                </div>
            </b-modal>
            <b-notification :closable="false">
                <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
            </b-notification>
        </section>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';
    import OutputCircBtns from '@/components/OutputCircBtns.vue';
    import CalculationTabs from '@/components/CalculationTabs.vue';
    import SaveButton from '@/components/SaveButton.vue';

    const DIGIT_EXPRESSION = /^\d$/;

    const isDigit = (character) => {
        return character && DIGIT_EXPRESSION.test(character);
    };

    export default {
        name: 'Calc_InputCircuit',
        components: {
            VerticalStepBar,
            OutputCircBtns,
            CalculationTabs,
            SaveButton
        },
        data() {
            return {
                isActive: true,
                labelPosition: 'on-border',
                isLoading: false,
                projectId: 0,
                currentPage: 1,
                circuitcalculationdevicesdata: [],
                conductordevicesdata: [],
                total_P_InputCircuit: 0,
                conductorTemp: 70,
                Tmax_inPanel: 0,

                inputCircuitCalcDeviceId: 0,
                definition_2: this.$lang.Calculation.Input + ' 1',
                definitionIndex_2: 0,
                type: '',
                typesList: [],
                brand: '',
                brandsList: [],
                code: '',
                codesList: [],
                in_A: '',
                in_valList: [],
                poleNumber: '',
                poleList: [],
                isPoleNumVisible: false,
                sbt_cekmeceli: '',
                sbt_cekmeceliList: [],
                is_sbt_cekmeceliVisible: false,
                power: '',
                powerList: [],
                isPowerVisible: false,
                Pv: 0,
                pvList: [],
                isPv_Visible: false,
                isInputCircuitDeviceUpdateModalActive: false,
                hasError_type: false,
                emptyWarning_type: '',
                hasError_brand: false,
                emptyWarning_brand: '',
                hasError_code: false,
                emptyWarning_code: '',
                hasError_in_A: false,
                emptyWarning_in_A: '',
                hasError_poleNumber: false,
                emptyWarning_poleNumber: '',
                hasError_sbt_cekmeceli: false,
                emptyWarning_sbt_cekmeceli: '',
                hasError_power: false,
                emptyWarning_power: '',
                hasError_Pv: false,
                emptyWarning_Pv: '',
                canCalculate_2: false,

                inputConductorDeviceId: 0,
                definition_3: this.$lang.Calculation.Input + ' 1',
                definitionIndex_3: 0,
                connType: '',
                connTypeList: [],
                cablePos: '',
                isCablePosVisible: false,
                cablePosList: [],
                phaseNumber_3: '',
                phaseNumber_3_List: [],
                conductorDimension: '',
                condDimensionList: [],
                conductorLength_3: 1,
                Imax_3: 0,
                lostPower_3: 0,
                p_conductor: 0,
                isConductorDeviceUpdateModalActive: false,
                hasError_connType: false,
                emptyWarning_connType: '',
                hasError_cablePos: false,
                emptyWarning_cablePos: '',
                hasError_phaseNumber_3: false,
                emptyWarning_phaseNumber_3: '',
                hasError_conductorDimension: false,
                emptyWarning_conductorDimension: '',
                hasError_conductorLength_3: false,
                emptyWarning_conductorLength_3: '',
                canCalculate_3: false
            }
        },
        mounted() {
            this.projectId = this.$store.state.projectId;
            this.Tmax_inPanel = this.$store.state.maxTemperature;

            this.getInputCircuitCalcDevicesTable();
            this.getInputCircuitDeviceConductorsTable();
            this.getTotal_P();

            this.getCircuitDevice_Types();
            this.getConnectionTypes();
            this.$store.state.activeStep = 3;
            this.$store.state.isFooterFixed = false;
        },
        watch: {
            $route() {

            }
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_Busbar');
            },
            submit() {
                this.saveResult();

                this.$store.isPageOpenedFromStepBar = false;
                this.$store.canResultOpen = true;

                router.push('/calc_OutputCircuit');
            },
            saveResult() {
                var totalP = this.$store.state.P_Busbar + this.$store.state.P_OutputCircuit + this.$store.state.P_ManuelCircuit + this.total_P_InputCircuit;
                var payload = { InputCircuitDef: this.definition_1 + " " + this.definitionIndex_1, P_InputCircuit: this.total_P_InputCircuit, totalLostPower_3: totalP };
                this.$store.commit('set_Datas_InputCircuits', payload);
            },
            isNumber(value) {
                return typeof value === 'number';
            },
            setPositiveNumber() {
                if (this.conductorLength_1 < 1) {
                    this.conductorLength_1 = 1;
                }

                if (this.conductorLength_3 < 1) {
                    this.conductorLength_3 = 1;
                }
            },
            resetCircDevice() {
                this.type = '';
                this.brand = '';
                this.code = '';
                this.in_A = '';
                this.poleNumber = '';
                this.isPoleNumVisible = false;
                this.sbt_cekmeceli = '';
                this.is_sbt_cekmeceliVisible = false;
                this.power = '';
                this.isPowerVisible = false;
                this.Pv = 0;
                this.isPv_Visible = false;
                this.isInputCircuitDeviceUpdateModalActive = false;
            },
            resetConductor() {
                this.connType = '';
                this.cablePos = '';
                this.phaseNumber_3 = '';
                this.conductorDimension = '';
                this.conductorLength_3 = 1;
                this.Imax_3 = 0;
                this.lostPower_3 = 0;
                this.p_conductor = 0;
                this.isConductorDeviceUpdateModalActive = false;
            },
            resetFieldList(index) {
                if (index == 1) {
                    this.brand = '';
                    this.code = '';
                    this.in_A = '';
                    this.poleNumber = 0;
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 2) {
                    this.code = '';
                    this.in_A = '';
                    this.poleNumber = 0;
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 3) {
                    this.in_A = '';
                    this.poleNumber = 0;
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 4) {
                    this.poleNumber = 0;
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 5) {
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 6) {
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 7) {
                    this.Pv = 0;
                }
            },
            resetFieldList_2(index) {
                if (index == 1) {
                    this.cablePos = '';
                    this.phaseNumber_3 = 0;
                    this.conductorDimension = '';
                } else if (index == 2) {
                    this.phaseNumber_3 = 0;
                    this.conductorDimension = '';
                } else if (index == 3) {
                    this.conductorDimension = '';
                }
            },

            getTotal_P() {
                this.total_P_InputCircuit = 0;
                api.panels.getTotal_P_InputCircuitCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_InputCircuit = res.data;

                        api.panels.getTotal_P_InputCircuitDeviceConductors(this.projectId).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.total_P_InputCircuit += res.data;
                                this.saveResult();
                            }
                        });
                    }
                });
            },

            getInputCircuitCalcDevicesTable() {
                this.circuitcalculationdevicesdata = [];
                api.panels.getInputCircuitCalcDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        var digitList = [];
                        res.data.forEach((e) => {
                            this.circuitcalculationdevicesdata.push(e);

                            var last = e.Definition.charAt(e.Definition.length - 1);
                            if (isDigit(last)) {
                                digitList.push(parseInt(last));
                            }
                        });

                        var maxDigit = 0;
                        for (let i = 0; i < digitList.length; i++) {
                            if (maxDigit < digitList[i]) {
                                maxDigit = digitList[i];
                            }
                        }

                        this.definitionIndex_2 = maxDigit + 1;
                        this.definition_2 = this.$lang.Calculation.Input + " " + this.definitionIndex_2;
                    }
                });
            },
            addToInputCircuitCalcDevices() {
                this.controlInputs_2();
                this.getCircuitDevice_Pv();
                if (this.canCalculate_2) {
                    const device = {
                        ProjectId: this.projectId,
                        Definition: this.definition_2,
                        Type: this.type,
                        Brand: this.brand,
                        Code: this.code,
                        In_Value: this.in_A,
                        Pole: this.poleNumber,
                        Sbt_Cekmeceli: this.sbt_cekmeceli,
                        Power: this.power,
                        Pv: this.Pv
                    };

                    api.panels.addToInputCircuitCalcDevices(device).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.$buefy.toast.open(this.$lang.OtherDialog.Added);
                            this.getInputCircuitCalcDevicesTable();
                            this.getTotal_P();
                            this.resetCircDevice();
                        }
                    });
                }
            },
            deleteFromInputCircuitCalcDevices(device) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.deleteFromInputCircuitCalcDevices(device).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                                this.getInputCircuitCalcDevicesTable();
                                this.getTotal_P();
                            }
                        })
                })
            },
            copyFromInputCircuitCalcDevices(device) {
                var initialDefinition = device.Definition;
                device.Definition = device.Definition + " " + this.$lang.CopyDialog.Extension;
                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.addToInputCircuitCalcDevices(device).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.CopyDialog.Copied);
                                this.getInputCircuitCalcDevicesTable();
                                this.getTotal_P();
                            }
                        }),
                    onCancel: () => {
                        device.Definition = initialDefinition;
                    }
                })
            },
            openUpdateInputCircuitCalcDeviceModal(device) {
                this.inputCircuitCalcDeviceId = device.Id;
                this.isInputCircuitDeviceUpdateModalActive = true;
                this.definition_2 = device.Definition;
                this.isLoading = true;

                this.type = device.Type;
                this.brandsList = [];
                var langCode = this.$lang.Format.Lang;
                api.panels.getCircuitDevice_Brands(this.type, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.brandsList.push(e);
                        });

                        this.brand = device.Brand;
                        this.codesList = [];
                        api.panels.getCircuitDevice_Codes(this.brand, this.type, langCode).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                res.data.forEach((e) => {
                                    this.codesList.push(e);
                                });

                                this.code = device.Code;
                                this.in_valList = [];
                                api.panels.getCircuitDevice_Ins(this.brand, this.type, this.code, langCode).then((res) => {
                                    if (res.isError) {
                                        console.log("error ");
                                    } else {
                                        res.data.forEach((e) => {
                                            this.in_valList.push(e);
                                        });

                                        this.in_A = device.In_Value;
                                        this.poleList = [];
                                        api.panels.getCircuitDevice_Pole(this.brand, this.type, this.code, this.in_A, langCode).then((res) => {
                                            if (res.isError) {
                                                console.log("error ");
                                            } else {
                                                res.data.forEach((e) => {
                                                    this.poleList.push(e);
                                                });
                                                if (this.poleList.length > 0) {
                                                    this.isPoleNumVisible = true;
                                                    if (this.poleList.length == 1 && this.poleList[0] == 'NA') {
                                                        this.isPoleNumVisible = false;
                                                    }
                                                } else {
                                                    this.isPoleNumVisible = false;
                                                }

                                                this.poleNumber = device.Pole;
                                                this.sbt_cekmeceliList = [];
                                                api.panels.getCircuitDevice_Sbt_Cekmeceli(this.brand, this.type, this.code, this.in_A, this.poleNumber, langCode).then((res) => {
                                                    if (res.isError) {
                                                        console.log("error ");
                                                    } else {
                                                        res.data.forEach((e) => {
                                                            this.sbt_cekmeceliList.push(e);
                                                        });
                                                        if (this.sbt_cekmeceliList.length > 0) {
                                                            this.is_sbt_cekmeceliVisible = true;
                                                            if (this.sbt_cekmeceliList.length == 1 && this.sbt_cekmeceliList[0] == 'NA') {
                                                                this.is_sbt_cekmeceliVisible = false;
                                                            }
                                                        } else {
                                                            this.is_sbt_cekmeceliVisible = false;
                                                        }

                                                        this.sbt_cekmeceli = device.Sbt_Cekmeceli;
                                                        this.powerList = [];
                                                        api.panels.getCircuitDevice_Power(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, langCode).then((res) => {
                                                            if (res.isError) {
                                                                console.log("error ");
                                                            } else {
                                                                res.data.forEach((e) => {
                                                                    this.powerList.push(e);
                                                                });
                                                                if (this.powerList.length > 0) {
                                                                    this.isPowerVisible = false;
                                                                    if (this.powerList.length == 1 && this.powerList[0] == 'NA') {
                                                                        this.isPowerVisible = true;
                                                                    }
                                                                } else {
                                                                    this.isPowerVisible = true;
                                                                }

                                                                this.power = device.Power;
                                                                this.pvList = [];
                                                                api.panels.getCircuitDevice_Pv(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, this.power, langCode).then((res) => {
                                                                    if (res.isError) {
                                                                        console.log("error ");
                                                                    } else {
                                                                        res.data.forEach((e) => {
                                                                            this.pvList.push(e);
                                                                        });

                                                                        this.Pv = device.Pv;
                                                                        this.isLoading = false;
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            },
            updateInputCircuitCalcDevices() {
                const device = {
                    Id: this.inputCircuitCalcDeviceId,
                    ProjectId: this.projectId,
                    Definition: this.definition_1,
                    Type: this.type,
                    Brand: this.brand,
                    Code: this.code,
                    In_Value: this.in_A,
                    Pole: this.poleNumber,
                    Sbt_Cekmeceli: this.sbt_cekmeceli,
                    Power: this.power,
                    Pv: this.Pv
                };
                api.panels.updateInputCircuitCalcDevices(device).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isInputCircuitDeviceUpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);
                        this.getInputCircuitCalcDevicesTable();
                        this.getTotal_P();
                    }
                });
            },
            getCircuitDevice_Types() {
                this.typesList = [];
                var langCode = this.$lang.Format.Lang;
                api.panels.getCircuitDevice_Types_InputCircs(langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.typesList.push(e);
                        });
                    }
                });
            },
            getCircuitDevice_Brands() {
                this.brandsList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Brands(this.type, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.brandsList.push(e);
                        });
                    }
                });
                this.resetFieldList(1);
            },
            getCircuitDevice_Codes() {
                this.codesList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Codes(this.brand, this.type, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.codesList.push(e);
                        });
                    }
                });
                this.resetFieldList(2);
            },
            getCircuitDevice_Ins() {
                this.in_valList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Ins(this.brand, this.type, this.code, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.in_valList.push(e);
                        });
                        if (this.in_valList.length == 0) {
                            this.in_A = null;
                        }
                    }
                });
                this.getCircuitDevice_Pole();
                this.resetFieldList(3);
            },
            getCircuitDevice_Pole() {
                this.poleList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Pole(this.brand, this.type, this.code, this.in_A, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.poleList.push(e);
                        });
                        if (this.poleList.length > 0) {
                            this.isPoleNumVisible = true;
                            if (this.poleList.length == 1 && this.poleList[0] == 'NA') {
                                this.isPoleNumVisible = false;
                                this.poleNumber = null;
                            }
                        } else {
                            this.isPoleNumVisible = false;
                            this.poleNumber = null;
                        }
                    }
                });
                this.getCircuitDevice_Sbt_Cekmeceli();
                this.resetFieldList(4);
            },
            getCircuitDevice_Sbt_Cekmeceli() {
                this.sbt_cekmeceliList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Sbt_Cekmeceli(this.brand, this.type, this.code, this.in_A, this.poleNumber, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.sbt_cekmeceliList.push(e);
                        });

                        if (this.sbt_cekmeceliList.length > 0) {
                            this.is_sbt_cekmeceliVisible = true;
                            if (this.sbt_cekmeceliList.length == 1 && this.sbt_cekmeceliList[0] == 'NA') {
                                this.is_sbt_cekmeceliVisible = false;
                                this.sbt_cekmeceli = null;
                            }
                        } else {
                            this.is_sbt_cekmeceliVisible = false;
                            this.sbt_cekmeceli = null;
                        }
                    }
                });
                this.getCircuitDevice_Power();
                this.resetFieldList(5);
            },
            getCircuitDevice_Power() {
                this.powerList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Power(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.powerList.push(e);
                        });
                        if (this.powerList.length > 0) {
                            this.isPowerVisible = true;
                            if (this.powerList.length == 1 && this.powerList[0] == 'NA') {
                                this.isPowerVisible = false;
                                this.power = null;
                            }
                        } else {
                            this.isPowerVisible = false;
                            this.power = null;
                        }
                    }
                });
                this.getCircuitDevice_Pv();
                this.resetFieldList(6);
            },
            getCircuitDevice_Pv() {
                this.pvList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Pv(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, this.power, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.pvList.push(e);
                        });
                        if (this.pvList.length > 0) {
                            this.isPv_Visible = true;
                            this.Pv = this.pvList[0];
                        } else {
                            this.isPv_Visible = false;
                            this.Pv = null;
                        }
                        this.isLoading = false;
                    }
                });
            },
            getInputCircuitDeviceConductorsTable() {
                this.conductordevicesdata = [];
                api.panels.getInputCircuitDeviceConductors(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        var digitList = [];
                        res.data.forEach((e) => {
                            this.conductordevicesdata.push(e);

                            var last = e.Definition.charAt(e.Definition.length - 1);
                            if (isDigit(last)) {
                                digitList.push(parseInt(last));
                            }
                        });

                        var maxDigit = 0;
                        for (let i = 0; i < digitList.length; i++) {
                            if (maxDigit < digitList[i]) {
                                maxDigit = digitList[i];
                            }
                        }
                        this.definitionIndex_3 = maxDigit + 1;
                        this.definition_3 = this.$lang.Calculation.Input + " " + this.definitionIndex_3;
                    }
                });
            },
            calculate_P_conductor() {
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                api.panels.calculate_P_Conductor(connTypeIndex, this.conductorLength_3, this.lostPower_3, this.phaseNumber_3).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.p_conductor = res.data;
                    }
                });
            },
            addToInputCircuitAndConductor() {
                this.controlInputs_2();
                this.controlInputs_3();
                var langCode = this.$lang.Format.Lang;

                if (this.canCalculate_2 && this.canCalculate_3) {
                    this.pvList = [];
                    api.panels.getCircuitDevice_Pv(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, this.power, langCode).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            res.data.forEach((e) => {
                                this.pvList.push(e);
                            });
                            if (this.pvList.length > 0) {
                                this.isPv_Visible = true;
                                this.Pv = this.pvList[0];

                                const device = {
                                    ProjectId: this.projectId,
                                    Definition: this.definition_2,
                                    Type: this.type,
                                    Brand: this.brand,
                                    Code: this.code,
                                    In_Value: this.in_A,
                                    Pole: this.poleNumber,
                                    Sbt_Cekmeceli: this.sbt_cekmeceli,
                                    Power: this.power,
                                    Pv: this.Pv
                                };
                                api.panels.addToInputCircuitCalcDevices(device).then((res) => {
                                    if (res.isError) {
                                        console.log("error ");
                                    } else {
                                        this.$buefy.toast.open(this.$lang.OtherDialog.Added);
                                        this.getInputCircuitCalcDevicesTable();
                                        this.getTotal_P();
                                        this.addToInputCircuitDeviceConductors();
                                    }
                                });
                            }
                        }
                    });
                }
            },
            addToInputCircuitDeviceConductors() {
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                api.panels.calculate_P_Conductor(connTypeIndex, this.conductorLength_3, this.lostPower_3, this.phaseNumber_3).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.p_conductor = res.data;

                        this.controlInputs_3();
                        if (this.canCalculate_3) {
                            const device = {
                                ProjectId: this.projectId,
                                Definition: this.definition_3,
                                ConnectionType: this.connType,
                                CablePosition: this.cablePos,
                                PhaseNumber: this.phaseNumber_3,
                                DimensionInfo: this.conductorDimension,
                                ConductorLength: this.conductorLength_3,
                                ConductorTemperature: this.conductorTemp,
                                MaxTemperature: this.Tmax_inPanel,
                                I_Max: this.Imax_3,
                                LostPower: this.lostPower_3,
                                CalcResult: this.p_conductor,
                                In_Value: this.in_A
                            };

                            api.panels.addToInputCircuitDeviceConductors(device).then((res) => {
                                if (res.isError) {
                                    console.log("error ");
                                } else {
                                    this.$buefy.toast.open(this.$lang.OtherDialog.Added);
                                    this.getInputCircuitDeviceConductorsTable();
                                    this.getTotal_P();
                                    this.resetConductor();
                                    this.resetCircDevice();
                                }
                            });
                        }
                    }
                });
            },
            deleteFromInputCircuitDeviceConductors(device) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.deleteFromInputCircuitDeviceConductors(device).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                                this.getInputCircuitDeviceConductorsTable();
                                this.getTotal_P();
                            }
                        })
                })
            },
            copyFromInputCircuitDeviceConductors(device) {
                var initialDefinition = device.Definition;
                device.Definition = device.Definition + " " + this.$lang.CopyDialog.Extension;
                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.addToInputCircuitDeviceConductors(device).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.CopyDialog.Copied);
                                this.getInputCircuitDeviceConductorsTable();
                                this.getTotal_P();
                            }
                        }),
                    onCancel: () => {
                        device.Definition = initialDefinition;
                    }
                })
            },
            openInputCircuitDeviceConductorModal(device) {
                this.in_A = device.In_Value;

                this.inputConductorDeviceId = device.Id;
                this.isConductorDeviceUpdateModalActive = true;
                this.definition_3 = device.Definition;
                this.connType = device.ConnectionType;
                this.getCablePositions();
                this.cablePos = device.CablePosition;
                this.phaseNumber_3 = device.PhaseNumber;
                this.getConductorDimensions();
                this.conductorDimension = device.DimensionInfo;
                this.conductorLength_3 = device.ConductorLength;
                this.Imax_3 = device.I_Max;
                this.lostPower_3 = device.LostPower;
                this.p_conductor = device.CalcResult;
            },
            updateInputCircuitDeviceConductors() {
                const device = {
                    Id: this.inputConductorDeviceId,
                    ProjectId: this.projectId,
                    Definition: this.definition_3,
                    ConnectionType: this.connType,
                    CablePosition: this.cablePos,
                    PhaseNumber: this.phaseNumber_3,
                    DimensionInfo: this.conductorDimension,
                    ConductorLength: this.conductorLength_3,
                    ConductorTemperature: this.conductorTemp,
                    MaxTemperature: this.Tmax_inPanel,
                    I_Max: this.Imax_3,
                    LostPower: this.lostPower_3,
                    CalcResult: this.p_conductor,
                    In_Value: this.in_A
                };

                api.panels.updateInputCircuitDeviceConductors(device).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isConductorDeviceUpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);
                        this.getInputCircuitDeviceConductorsTable();
                        this.getTotal_P();
                    }
                });
            },
            getConnectionTypes() {
                this.connTypeList = [];
                var langCode = this.$lang.Format.Lang;
                api.panels.getConnectionTypes(langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.connTypeList.push(e.typeName);
                        });
                    }
                });
            },
            getCablePositions() {
                this.cablePosList = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                if (connTypeIndex == 2) {
                    this.isCablePosVisible = true;

                    api.panels.getCablePositions().then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            res.data.forEach((e) => {
                                this.cablePosList.push(e.positionName);
                            });
                        }
                    });
                } else {
                    this.isCablePosVisible = false;
                    this.getConductorDimensions();
                }
                this.getPhaseNumbers();
                this.resetFieldList_2(1);
            },
            getPhaseNumbers() {
                this.phaseNumber_3_List = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                api.panels.getPhaseNumbers(connTypeIndex).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.phaseNumber_3_List.push(e);
                        });
                    }
                });
                this.resetFieldList_2(2);
            },
            getConductorDimensions() {
                this.condDimensionList = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                var cablePosId = this.cablePosList.indexOf(this.cablePos) + 1;
                api.panels.getConductorDimensions(connTypeIndex, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.condDimensionList.push(e.DimensionInfo);
                        });
                    }
                });
                this.resetFieldList_2(3);
            },
            getConductor_Imax_and_Power() {
                this.getConductor_I_Max();
                this.getConductor_LostPower();
            },
            getConductor_I_Max() {
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                var dimensionId = this.condDimensionList.indexOf(this.conductorDimension) + 1;
                var cablePosId = this.cablePosList.indexOf(this.cablePos) + 1;
                api.panels.getConductor_I_Max(connTypeIndex, dimensionId, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.Imax_3 = res.data;
                    }
                });
            },
            getConductor_LostPower() {
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                var dimensionId = this.condDimensionList.indexOf(this.conductorDimension) + 1;
                var cablePosId = this.cablePosList.indexOf(this.cablePos) + 1;
                api.panels.GetConductor_LostPower(connTypeIndex, dimensionId, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.lostPower_3 = res.data;
                    }
                });
            },
            truncate(numToBeTruncated, numOfDecimals) {
                var theNumber = numToBeTruncated.toString();
                if (theNumber.includes('.')) {
                    var pointIndex = theNumber.indexOf('.');
                    var _result = +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
                    var _resultString = _result.toString();
                    if (!_resultString.includes('.')) {
                        return _resultString + '.0';
                    } else {
                        return _result;
                    }
                } else {
                    return theNumber + '.0';
                }
            },
            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },

            controlInputs_2() {
                if (this.type == '') {
                    this.hasError_type = true;
                    this.emptyWarning_type = this.$lang.Menus.EmptyWarning;
                } else if (this.brand == '') {
                    this.hasError_brand = true;
                    this.emptyWarning_brand = this.$lang.Menus.EmptyWarning;
                } else if (this.code == '') {
                    this.hasError_code = true;
                    this.emptyWarning_code = this.$lang.Menus.EmptyWarning;
                } else if (this.in_A == '') {
                    this.hasError_in_A = true;
                    this.emptyWarning_in_A = this.$lang.Menus.EmptyWarning;
                } else if (this.poleNumber == '') {
                    if (this.isPoleNumVisible) {
                        this.hasError_poleNumber = true;
                        this.emptyWarning_poleNumber = this.$lang.Menus.EmptyWarning;
                    }
                } else if (this.sbt_cekmeceli == '') {
                    if (this.is_sbt_cekmeceliVisible) {
                        this.hasError_sbt_cekmeceli = true;
                        this.emptyWarning_sbt_cekmeceli = this.$lang.Menus.EmptyWarning;
                    }
                } else if (this.power == '') {
                    if (this.isPowerVisible) {
                        this.hasError_power = true;
                        this.emptyWarning_power = this.$lang.Menus.EmptyWarning;
                    }
                } else {
                    this.canCalculate_2 = true;
                }

                if (this.type != '') {
                    this.hasError_type = false;
                    this.emptyWarning_type = '';
                }
                if (this.brand != '') {
                    this.hasError_brand = false;
                    this.emptyWarning_brand = '';
                }
                if (this.code != '') {
                    this.hasError_code = false;
                    this.emptyWarning_code = '';
                }
                if (this.in_A != '') {
                    this.hasError_in_A = false;
                    this.emptyWarning_in_A = '';
                }
                if (this.isPoleNumVisible && this.poleNumber != '') {
                    this.hasError_poleNumber = false;
                    this.emptyWarning_poleNumber = '';
                }
                if (this.is_sbt_cekmeceliVisible && this.sbt_cekmeceli != '') {
                    this.hasError_sbt_cekmeceli = false;
                    this.emptyWarning_sbt_cekmeceli = '';
                }
                if (this.isPowerVisible && this.power != '') {
                    this.hasError_power = false;
                    this.emptyWarning_power = '';
                }
            },
            controlInputs_3() {
                if (this.connType == '') {
                    this.hasError_connType = true;
                    this.emptyWarning_connType = this.$lang.Menus.EmptyWarning;
                } else if (this.phaseNumber_3 == '') {
                    this.hasError_phaseNumber_3 = true;
                    this.emptyWarning_phaseNumber_3 = this.$lang.Menus.EmptyWarning;
                } else if (this.conductorDimension == '') {
                    this.hasError_conductorDimension = true;
                    this.emptyWarning_conductorDimension = this.$lang.Menus.EmptyWarning;
                } else if (this.conductorLength_3 == '') {
                    this.hasError_conductorLength_3 = true;
                    this.emptyWarning_conductorLength_3 = this.$lang.Menus.EmptyWarning;
                } else {
                    this.canCalculate_3 = true;
                }

                if (this.isCablePosVisible) {
                    if (this.cablePos == '') {
                        this.hasError_cablePos = true;
                        this.emptyWarning_cablePos = this.$lang.Menus.EmptyWarning;
                    }
                }

                if (this.connType != '') {
                    this.hasError_connType = false;
                    this.emptyWarning_connType = '';
                }
                if (this.cablePos != '') {
                    this.hasError_cablePos = false;
                    this.emptyWarning_cablePos = '';
                }
                if (this.phaseNumber_3 != '') {
                    this.hasError_phaseNumber_3 = false;
                    this.emptyWarning_phaseNumber_3 = '';
                }
                if (this.conductorDimension != '') {
                    this.hasError_conductorDimension = false;
                    this.emptyWarning_conductorDimension = '';
                }
                if (this.conductorLength_3 != '') {
                    this.hasError_conductorLength_3 = false;
                    this.emptyWarning_conductorLength_3 = '';
                }
            },
            openCircuitDevices() {
                this.$store.state.previousRoute = this.$route.name;
                this.$store.state.isCircDevicesFromCalculation = true;
                if (this.$store.state.memberId != 'TestMember' && this.$store.state.isAdmin == 1) {
                    router.push('/circuitDevices_Admin');
                } else {
                    router.push('/circuitDevices');
                }
            }
        }
    };
</script>

<style>
    .material-symbols-outlined {
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }
</style>
